import { useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import AgendaItemIssues from '@/application/pages/agenda/components/AgendaItemIssues';
import AgendaItemMeta from '@/application/pages/agenda/components/AgendaItemMeta';
import AgendaItemModal from '@/application/pages/agenda/components/AgendaItemModal';
import { Important } from '@/shared/components/Important';
import LineDivider from '@/shared/components/LineDivider';
import { AgendaItem } from '@/shared/types/models';

import AddAgendaItemButton from './AddAgendaItemButton';

function AgendaListItem({ item }: { item: AgendaItem }) {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const important = item.agenda_item_companies.some((company) => company.important);
  return (
    <>
      <div role="listitem">
        <div
          className="d-md-flex justify-content-between align-items-center mb-4"
          role="button"
          onClick={() => setShowModal(true)}
          onKeyPress={() => setShowModal(true)}
          tabIndex={0}
        >
          <div>
            <h3 className="mb-2">
              {important && <Important />}
              {item.title}
              {item.is_cancelled
                            && (
                            <span className="text-uppercase">
                              {' '}
                              (
                              {t('message.cancelled')}
                              )
                            </span>
                            )}
            </h3>

            <div className="mb-2">
              <AgendaItemMeta
                start_time={item.start_time}
                end_time={item.end_time}
                agenda_category={item.agenda_category}
              />
            </div>

            <div className="mb-2 mb-md-0">
              <AgendaItemIssues issues={item.issues} />
            </div>
          </div>

          <div className="text-nowrap">
            <AddAgendaItemButton id={item.id} />
          </div>
        </div>
      </div>

      <AgendaItemModal
        showModal={showModal}
        closeHandler={() => setShowModal(false)}
        {...item}
      />
      <LineDivider />
    </>
  );
}

export default AgendaListItem;
